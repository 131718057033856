import Swiper from "swiper";

function swiper($selector, $options) {
  return new Swiper($selector, $options);
}

swiper(".hero__sliderContainer", {
  slidersPerView: 1,
  effect: "fade",
  fadeEffect: { crossFade: true },
  // loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  // pagination: {
  //   el: ".hero__sliderPagination",
  //   dynamicBullets: true,
  //   clickable: true
  // }
});

/* Testimonials */
swiper(".content__section--swiper-testimonials", {
  autoHeight: true,
  fadeEffect: { crossFade: true },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  }
});

/* News Articles */
swiper(".latest-news__carousel--swiper", {
  slidersPerView: 1,
  spaceBetween: 10,
  arrows: false,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 30
    }
  }
});

swiper(".services__carousel--swiper", {
  slidersPerView: 1,
  spaceBetween: 5,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    1024: {
      slidesPerView: 3,
      spaceBetween: 5
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 5
    }
  }
});

swiper(".accreditations__logos--carousel--swiper", {
  spaceBetween: 30,
  slidesPerView: 1,
  autoplay: true,

  breakpoints: {
    375: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 40
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 50
    }
  }
});

swiper(".footer__carousel--swiper", {});

document.querySelectorAll(".editor-block__carousel--rendered").forEach(item => {
  let config = JSON.parse(item.dataset.config);
  swiper(item, {
    spaceBetween: 15,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    breakpoints: {
      568: {
        slidesPerView: config.slidesPerView
      }
    }
  });
});
